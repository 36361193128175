












import { defineComponent } from '@vue/composition-api';
import TheHeader from '@/components/Header.vue';
import TheFooter from '@/components/Footer.vue';

import TheForm from '@/components/Formulario.vue';

export default defineComponent({
  name: 'App',

  components: {
    TheHeader,
    TheForm,
    TheFooter,
    // HelloWorld,
  },
});
