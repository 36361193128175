import Vue from 'vue';
import App from './App.vue';
import '@/app/styles/app.postcss';
import VueFormulate from '@braid/vue-formulate';
import Compositon from '@vue/composition-api';

Vue.use(Compositon);
Vue.use(VueFormulate);
Vue.config.productionTip = false;
import '@/app.scss';
new Vue({
  render: (h) => h(App),
}).$mount('#app');
